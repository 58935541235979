import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, Facebook, ArrowUp } from 'lucide-react';
import { PageContainer } from './PageContainer';

const MainFooter = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-retro-purple text-white pt-16">
      <PageContainer className="pb-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-16">
          <div>
            <h3 className="font-display uppercase mb-6 text-retro-pink text-xl">Navigation</h3>
            <ul className="space-y-4">
              <li>
                <Link to="/actualites" className="text-white/80 hover:text-white transition-colors">
                  Actualités
                </Link>
              </li>
              <li>
                <Link to="/materiel" className="text-white/80 hover:text-white transition-colors">
                  Matériel
                </Link>
              </li>
              <li>
                <Link to="/boutique" className="text-white/80 hover:text-white transition-colors">
                  Boutique
                </Link>
              </li>
              <li>
                <Link to="/news-submit" className="text-white/80 hover:text-white transition-colors">
                  🪂
                </Link>
              </li>              
            </ul>
          </div>

          <div>
            <h3 className="font-display uppercase mb-6 text-retro-orange text-xl">Liens Utiles</h3>
            <ul className="space-y-4">
              <li>
                <a 
                  href="https://federation.ffvl.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors"
                >
                  FFVL
                </a>
              </li>
              <li>
                <a 
                  href="https://www.spotair.mobi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors"
                >
                  SpotAir
                </a>
              </li>
              <li>
                <Link to="/mentions-legales" className="text-white/80 hover:text-white transition-colors">
                  Informations légales
                </Link>
              </li>
              <li>
                <a 
                  href="https://volavolo.notion.site/To-do-1517e91ef0998115adefded0950829a7"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors"
                >
                  Roadmap du site
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-display uppercase mb-6 text-retro-blue text-xl">Suivez-nous</h3>
            <ul className="space-y-4">
              <li>
                <a href="https://www.instagram.com/volavolo.parapente/" 
                   target="_blank"
                   rel="noopener noreferrer"
                   className="flex items-center gap-2 text-white/80 hover:text-white transition-colors">
                  <Instagram className="w-5 h-5" />
                  VolaVolo.Parapente
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/VolaVolo.parapente" 
                   target="_blank"
                   rel="noopener noreferrer"
                   className="flex items-center gap-2 text-white/80 hover:text-white transition-colors">
                  <Facebook className="w-5 h-5" />
                  VolaVolo.Parapente
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-16">
          <Link to="/" className="inline-block mb-6">
            <span className="text-3xl font-display uppercase tracking-wider">
              <span className="text-white">Vola</span><span className="rainbow-text-light">volo</span>
            </span>
          </Link>
          <p className="text-white/80 text-base leading-normal max-w-2xl">
            Le site des passionnés de parapente. <br />
            La plateforme d'information de ceux qui aiment s'envoyer en l'air vers l'infini et au delà.
          </p>
        </div>

        <div className="h-px bg-white/10 my-12"></div>

        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-white/60 text-sm">
            © {new Date().getFullYear()} VolaVolo. Tous droits réservés.
          </p>
          <button
            onClick={scrollToTop}
            className="group flex items-center gap-2 text-white/60 hover:text-white transition-colors"
          >
            Haut de page
            <ArrowUp className="w-4 h-4 group-hover:-translate-y-1 transition-transform" />
          </button>
        </div>
      </PageContainer>
    </footer>
  );
};

export default MainFooter;