import React, { useState, useEffect } from 'react';
import { ProductSection } from './ProductSection';
import { ConstructionModal } from '../ui/ConstructionModal';
import type { Product } from '../../types/shop';

const products: Record<string, Product[]> = {
  tshirts: [
    {
      name: "T-shirt Logo Classique",
      price: "29.99 €",
      image: "https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?auto=format&fit=crop&w=800&q=80",
      colors: ["#200943", "#FF69B4", "#FFFFFF"],
      sizes: ["S", "M", "L", "XL"],
      isNew: true,
      description: {
        product: "T-shirt unisexe au design épuré arborant le logo VolaVolo sur le cœur et un motif subtil de parapente sur le dos.",
        fabric: "100% coton bio peigné et filé en anneau, 180g/m²",
        manufacturing: "Fabriqué en France dans un atelier certifié GOTS",
        care: "Lavage à 30°C, repassage sur l'envers, séchage à plat"
      }
    }
  ],
  hoodies: [
    {
      name: "Hoodie Premium",
      price: "59.99 €",
      image: "https://images.unsplash.com/photo-1556821840-3a63f95609a7?auto=format&fit=crop&w=800&q=80",
      colors: ["#200943", "#FF69B4"],
      sizes: ["S", "M", "L", "XL"],
      isNew: true,
      description: {
        product: "Sweat à capuche confortable avec poche kangourou, cordon de serrage plat et logo VolaVolo brodé.",
        fabric: "85% coton bio et 15% polyester recyclé, 320g/m²",
        manufacturing: "Production éthique au Portugal, certifiée Fair Wear Foundation",
        care: "Lavage à 30°C sur l'envers, pas de sèche-linge"
      }
    }
  ],
  accessories: [
    {
      name: "Casquette Logo",
      price: "24.99 €",
      image: "https://images.unsplash.com/photo-1588850561407-ed78c282e89b?auto=format&fit=crop&w=800&q=80",
      colors: ["#200943", "#FF69B4", "#FF8C42"]
    },
    {
      name: "Chaussettes Volantes",
      price: "12.99 €",
      image: "https://images.unsplash.com/photo-1586350977771-b3b0abd50c82?auto=format&fit=crop&w=800&q=80",
      colors: ["#200943", "#FF69B4", "#00B4D8"]
    },
    {
      name: "Pack 5 Stickers",
      price: "9.99 €",
      image: "https://images.unsplash.com/photo-1612404819070-77c6da472e68?auto=format&fit=crop&w=800&q=80",
      colors: ["#FF69B4", "#FF8C42", "#00B4D8"]
    }
  ]
};

export default function ShopPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <div className="min-h-[73vh] bg-white dark:bg-retro-purple/95">
      <section className="pt-36 pb-20 px-4 relative overflow-hidden bg-gradient-to-b from-white dark:from-retro-purple to-retro-purple/5 dark:to-retro-purple/20">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,#FF69B4_0%,transparent_70%)] opacity-10"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,#00B4D8_0%,transparent_70%)] opacity-10"></div>
        
        <div className="max-w-[1200px] mx-auto relative z-10">
          <h2 className="text-4xl md:text-6xl font-display uppercase mb-6 leading-tight text-retro-purple dark:text-white retro-shadow">
            L'espace VolaVolo
          </h2>
          
          <p className="text-xl text-retro-purple/80 dark:text-white/80 max-w-2xl mb-8">
            Un anniversaire, un baptême, une retraite, une démission, ou une toiture à refaire, n'en dites pas plus vous avez perdu un pari ?! Eheh nous aussi... Quoi qu'il en soit, et quoi qu'il en coûte vous voulez porter du beau, vous voulez être dans l'équipe, vous êtes de la partie ?! Eheh nous aussi...
          </p>
        </div>
      </section>

      <ProductSection
        title="T-shirts"
        description="Des t-shirts éco-confortables en coco-tonton avec une impression sur le coeur et dans le dos."
        gradient="bg-gradient-to-r from-retro-pink via-retro-orange to-retro-yellow"
        products={products.tshirts}
        layout="large"
      />

      <div className="max-w-[800px] mx-auto px-4">
        <div className="h-px bg-gradient-to-r from-transparent via-retro-purple/10 dark:via-white/10 to-transparent"></div>
      </div>

      <ProductSection
        title="Sweats & Hoodies"
        description="Soyez au chaud en consultant vos fichiers météos."
        gradient="bg-gradient-to-r from-retro-blue via-retro-purple to-retro-pink"
        products={products.hoodies}
        layout="large"
      />

      <div className="max-w-[800px] mx-auto px-4">
        <div className="h-px bg-gradient-to-r from-transparent via-retro-purple/10 dark:via-white/10 to-transparent"></div>
      </div>

      <ProductSection
        title="Accessoires"
        description="Complétez ou initiez une panoplie remarquable en faisant l'acquisition de ces éléments de détails qui constituent la perfection."
        gradient="bg-gradient-to-r from-retro-green via-retro-blue to-retro-purple"
        products={products.accessories}
        layout="square"
      />

      <ConstructionModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
      />
    </div>
  );
}