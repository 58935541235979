import React from 'react';
import { X } from 'lucide-react';

interface ConstructionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ConstructionModal({ isOpen, onClose }: ConstructionModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm" 
        onClick={onClose}
      />
      
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="relative bg-white dark:bg-retro-purple/95 rounded-lg w-full max-w-md p-8 shadow-xl border-2 border-retro-pink/50">
          <div className="absolute inset-0 bg-gradient-to-r from-retro-pink via-retro-orange to-retro-blue opacity-10 rounded-lg"></div>
          
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-retro-purple/60 dark:text-white/60 hover:text-retro-pink transition-colors"
          >
            <X className="w-6 h-6" />
          </button>

          <div className="text-center">
            <div className="text-4xl mb-4">🏗️</div>
            
            <h2 className="text-2xl font-display uppercase mb-6 text-retro-purple dark:text-white">
              Page en construction
            </h2>

            <p className="text-retro-purple/80 dark:text-white/80">
              Mais librement consultable<br />-<br />
              Merci pour la compréhension
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}