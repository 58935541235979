import React from 'react';
import { CheckCircle } from 'lucide-react';

export function SuccessMessage() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm z-50">
      <div className="bg-white dark:bg-retro-purple p-8 rounded-lg shadow-xl max-w-md w-full mx-4 transform translate-y-0">
        <div className="text-center space-y-4">
          <div className="flex justify-center">
            <CheckCircle className="w-16 h-16 text-green-500" />
          </div>
          <h2 className="text-2xl font-display text-retro-purple dark:text-white">
            Merci pour votre contribution !
          </h2>
          <p className="text-retro-purple/80 dark:text-white/80">
            Votre actualité a été soumise avec succès et sera examinée par notre équipe.
          </p>
        </div>
      </div>
    </div>
  );
}