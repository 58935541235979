import { api } from '../../../lib/api';
import type { News, CreateNewsData } from '../newsTypes';

export async function getAllNews(): Promise<News[]> {
  const data = await api.news.getAll();
  
  return data.map(item => ({
    id: item.id,
    title: item.title,
    content: item.content,
    category: item.category,
    mainImage: item.main_image || '',
    additionalImages: item.additional_images || [],
    authorName: item.profiles?.first_name 
      ? `${item.profiles.first_name} ${item.profiles.last_name || ''}`
      : 'Anonyme',
    authorType: item.profiles?.role || 'guest',
    createdAt: item.created_at
  }));
}

export async function getNewsById(id: string): Promise<News> {
  const item = await api.news.getById(id);
  
  return {
    id: item.id,
    title: item.title,
    content: item.content,
    category: item.category,
    mainImage: item.main_image || '',
    additionalImages: item.additional_images || [],
    authorName: item.profiles?.first_name 
      ? `${item.profiles.first_name} ${item.profiles.last_name || ''}`
      : 'Anonyme',
    authorType: item.profiles?.role || 'guest',
    createdAt: item.created_at,
    publishedDate: item.published_date,
    originalArticleUrl: item.original_article_url
  };
}

export async function createNews(data: CreateNewsData): Promise<News> {
  const news = await api.news.create({
    title: data.title,
    content: data.content,
    category: data.category,
    main_image: data.mainImage,
    additional_images: data.additionalImages,
    author_id: '00000000-0000-0000-0000-000000000000',
    status: 'published',
    excerpt: data.content.slice(0, 160) + '...'
  });

  return {
    id: news.id,
    title: news.title,
    content: news.content,
    category: news.category,
    mainImage: news.main_image || '',
    additionalImages: news.additional_images || [],
    authorName: 'Anonyme',
    authorType: 'guest',
    createdAt: news.created_at
  };
}

export async function getAdjacentNews(currentId: string): Promise<{ 
  previous: News | null; 
  next: News | null; 
}> {
  const allNews = await getAllNews();
  const currentIndex = allNews.findIndex(news => news.id === currentId);
  
  if (currentIndex === -1) return { previous: null, next: null };

  return {
    previous: allNews[currentIndex + 1] || null,
    next: allNews[currentIndex - 1] || null
  };
}