import { z } from 'zod';
import { NEWS_CATEGORIES } from './newsTypes';

export const newsSchema = z.object({
  title: z.string()
    .min(3, 'Le titre doit contenir au moins 3 caractères')
    .max(80, 'Le titre ne doit pas dépasser 80 caractères'),
  
  content: z.string()
    .min(100, 'Le contenu doit contenir au moins 100 caractères')
    .max(10000, 'Le contenu ne doit pas dépasser 10000 caractères'),
  
  category: z.enum(NEWS_CATEGORIES, {
    errorMap: () => ({ message: 'Veuillez sélectionner une catégorie' })
  }),
  publishedDate: z.string()
    .min(1, 'La date de publication est requise'),
  originalArticleUrl: z.string().url('URL invalide').nullish(),
  
  mainImage: z.string().optional(),
  additionalImages: z.array(z.string()).optional()
});

export type NewsFormData = z.infer<typeof newsSchema>;

export function validateNews(data: unknown) {
  return newsSchema.safeParse(data);
}