import React from 'react';
import { cn } from '../../utils/cn';

interface PageContainerProps {
  children: React.ReactNode;
  className?: string;
}

export function PageContainer({ children, className }: PageContainerProps) {
  return (
    <div className={cn(
      'mx-auto px-2 md:px-4',
      'w-full max-w-screen-2xl',
      className
    )}>
      {children}
    </div>
  );
}