import React, { useState } from 'react';
import { X, ArrowUp, ArrowDown, Trash2 } from 'lucide-react';
import { uploadImage } from '../../lib/storage';
import { logger } from '../../utils/debug';

interface ImageUploaderProps {
  mainImage: string;
  additionalImages: string[];
  onMainImageChange: (url: string) => void;
  onAdditionalImagesChange: (urls: string[]) => void;
  onError: (error: Error) => void;
}

export function ImageUploader({
  mainImage,
  additionalImages,
  onMainImageChange,
  onAdditionalImagesChange,
  onError
}: ImageUploaderProps) {
  const [isUploading, setIsUploading] = useState(false);

  const handleImageUpload = async (file: File, isMain: boolean = false) => {
    try {
      setIsUploading(true);
      
      // Validation du type de fichier
      const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!validTypes.includes(file.type)) {
        throw new Error('Format d\'image non supporté. Utilisez JPG, PNG ou WEBP.');
      }

      // Validation de la taille
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('L\'image ne doit pas dépasser 5MB');
      }

      // Upload de l'image
      const url = await uploadImage(file);
      
      if (isMain) {
        onMainImageChange(url);
      } else {
        if (additionalImages.length >= 5) {
          throw new Error('Maximum 5 images supplémentaires autorisées');
        }
        onAdditionalImagesChange([...additionalImages, url]);
      }
    } catch (error) {
      logger.error('Image upload error:', error);
      onError(error instanceof Error ? error : new Error('Erreur lors de l\'upload'));
    } finally {
      setIsUploading(false);
    }
  };

  const moveImage = (fromIndex: number, toIndex: number) => {
    const newImages = [...additionalImages];
    const [movedImage] = newImages.splice(fromIndex, 1);
    newImages.splice(toIndex, 0, movedImage);
    onAdditionalImagesChange(newImages);
  };

  const removeImage = (index: number) => {
    onAdditionalImagesChange(additionalImages.filter((_, i) => i !== index));
  };

  const removeMainImage = () => {
    onMainImageChange('');
  };

  return (
    <div className="space-y-6">
      {/* Image principale */}
      <div>
        <label className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
          Image principale
        </label>
        <div className="relative">
          <input
            type="file"
            accept="image/jpeg,image/png,image/webp"
            className="hidden"
            id="main-image"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) handleImageUpload(file, true);
            }}
            disabled={isUploading}
          />
          <label
            htmlFor="main-image"
            className="block w-full aspect-video rounded-lg border-2 border-dashed 
                     border-retro-purple/10 dark:border-white/10 
                     hover:border-retro-pink transition-colors cursor-pointer
                     overflow-hidden"
          >
            {mainImage ? (
              <div className="relative w-full h-full">
                <img
                  src={mainImage}
                  alt="Image principale"
                  className="w-full h-full object-cover"
                />
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    removeMainImage();
                  }}
                  className="absolute top-4 right-4 p-2 bg-white/90 dark:bg-retro-purple/90 rounded-full
                           text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
                <div className="absolute bottom-0 left-0 right-0 bg-black/50 text-white p-2 text-sm">
                  Cliquez pour changer l'image
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-retro-purple/60 dark:text-white/60">
                  {isUploading ? 'Upload en cours...' : 'Cliquez pour ajouter une image principale'}
                </p>
              </div>
            )}
          </label>
        </div>
      </div>

      {/* Images supplémentaires */}
      <div>
        <label className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
          Images supplémentaires (max 5)
        </label>
        <div className="space-y-4">
          {additionalImages.map((image, index) => (
            <div
              key={index}
              className="flex items-center gap-4 p-4 bg-white dark:bg-retro-purple/30 rounded-lg"
            >
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="w-20 h-20 object-cover rounded"
              />
              <div className="flex-1 flex items-center justify-end gap-2">
                <button
                  type="button"
                  onClick={() => removeImage(index)}
                  className="p-2 text-red-500 hover:text-red-600 transition-colors"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  onClick={() => index > 0 && moveImage(index, index - 1)}
                  disabled={index === 0}
                  className="p-2 text-retro-purple dark:text-white hover:text-retro-pink 
                           disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  <ArrowUp className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  onClick={() => index < additionalImages.length - 1 && moveImage(index, index + 1)}
                  disabled={index === additionalImages.length - 1}
                  className="p-2 text-retro-purple dark:text-white hover:text-retro-pink 
                           disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  <ArrowDown className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}

          {additionalImages.length < 5 && (
            <div>
              <input
                type="file"
                accept="image/jpeg,image/png,image/webp"
                className="hidden"
                id="additional-image"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) handleImageUpload(file);
                }}
                disabled={isUploading}
              />
              <label
                htmlFor="additional-image"
                className="block w-full p-4 text-center rounded-lg border-2 border-dashed 
                         border-retro-purple/10 dark:border-white/10 
                         hover:border-retro-pink transition-colors cursor-pointer"
              >
                <p className="text-retro-purple/60 dark:text-white/60">
                  {isUploading ? 'Upload en cours...' : 'Ajouter une image supplémentaire'}
                </p>
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}