import React, { useState, useEffect } from 'react';

interface SubmitButtonProps {
  isSubmitting: boolean;
}

export function SubmitButton({ isSubmitting }: SubmitButtonProps) {
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      setShowSuccess(true);
      const timer = setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSubmitting]);

  return (
    <button
      type="submit"
      disabled={isSubmitting}
      className={`
        flex-1 px-6 py-3 rounded-lg font-display uppercase tracking-wider
        transition-all duration-300 ease-in-out
        disabled:opacity-50 disabled:cursor-not-allowed
        ${showSuccess 
          ? 'bg-green-500 hover:bg-green-600 text-white shadow-[4px_4px_0px_0px_#22543D]' 
          : 'btn-primary'
        }
      `}
    >
      <span className={`transition-opacity duration-300 ${showSuccess ? 'opacity-0' : 'opacity-100'}`}>
        Publier l'actualité
      </span>
      <span 
        className={`absolute left-1/2 -translate-x-1/2 transition-opacity duration-300 ${
          showSuccess ? 'opacity-100' : 'opacity-0'
        }`}
      >
        Actualité envoyée au comité de lecture
      </span>
    </button>
  );
}