import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import MainNavigation from './components/layout/MainNavigation';
import MainFooter from './components/layout/MainFooter';
import HomeHero from './components/home/HomeHero';
import AuthPage from './pages/AuthPage';
import NewsPageGrid from './components/news/NewsPageGrid';
import EquipmentPageGrid from './components/equipment/EquipmentPageGrid';
import EquipmentDetail from './pages/equipment/EquipmentDetail';
import EquipmentPageHero from './components/equipment/EquipmentPageHero';
import NewsSubmit from './pages/news/NewsSubmit';
import NewsDetail from './pages/news/NewsDetail';
import NewsPageHero from './components/news/NewsPageHero';
import ShopPageHero from './components/shop/ShopPageHero';
import LegalNotice from './components/LegalNotice';
import NewsAdditionPage from './pages/news/NewsAdditionPage';
import ShopPage from './components/shop/ShopPage';
import SearchPage from './pages/SearchPage';
import Error404Page from './pages/errors/Error404Page';
import Error403Page from './pages/errors/Error403Page';
import Error400Page from './pages/errors/Error400Page';
import { useScrollToTop } from './hooks/useScrollToTop';

function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <div className="min-h-screen bg-white dark:bg-retro-purple/95 flex flex-col">
        <MainNavigation />
        <div className="flex-grow">
          <Routes>
          <Route path="/" element={
            <>
              <HomeHero />
              <NewsPageGrid />
              <EquipmentPageGrid />
            </>
          } />
          <Route path="/actualites" element={
            <main>
              <NewsPageHero />
              <NewsPageGrid />
            </main>
          } />
          <Route path="/actualites/:id" element={<NewsDetail />} />
          <Route path="/materiel" element={
            <main>
              <EquipmentPageHero />
              <EquipmentPageGrid />
            </main>
          } />
          <Route path="/materiel/:slug" element={<EquipmentDetail />} />
          <Route path="/boutique" element={<ShopPage />} />
          <Route path="/mentions-legales" element={<LegalNotice />} />
          <Route path="/news-addition" element={<NewsAdditionPage />} />
          <Route path="/news-submit" element={<NewsSubmit />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/auth" element={<AuthPage />} />
          
          {/* Pages d'erreur */}
          <Route path="/400" element={<Error400Page />} />
          <Route path="/403" element={<Error403Page />} />
          <Route path="*" element={<Error404Page />} />
          </Routes>
        </div>
        <MainFooter />
      </div>
    </AuthProvider>
  );
}

export default App;