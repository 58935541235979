import React, { useState } from 'react';
import type { CreateNewsData } from '../newsTypes';
import { ImageUploader } from '../../../components/ui/ImageUploader';
import { validateNews } from '../validation';
import { SubmitButton } from './SubmitButton';
import { FormField } from './FormField';
import { CategorySelect } from './CategorySelect';
import { MarkdownGuide } from './MarkdownGuide';

interface NewsFormProps {
  onSubmit: (data: CreateNewsData) => Promise<void>;
  isSubmitting: boolean;
  error?: string;
}

export function NewsForm({ onSubmit, isSubmitting, error }: NewsFormProps) {
  const [formData, setFormData] = useState<CreateNewsData>({
    title: '',
    content: '',
    category: '',
    publishedDate: new Date().toISOString().split('T')[0],
    mainImage: '',
    additionalImages: []
  });
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [uploadError, setUploadError] = useState<string>('');
  const [showMarkdownGuide, setShowMarkdownGuide] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setValidationErrors({});
    setUploadError('');

    const validation = validateNews(formData);
    if (!validation.success) {
      const errors: Record<string, string> = {};
      validation.error.issues.forEach(issue => {
        errors[issue.path[0]] = issue.message;
      });
      setValidationErrors(errors);
      return;
    }

    await onSubmit(formData);
  };

  const handleImageError = (error: Error) => {
    setUploadError(error.message);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8" encType="multipart/form-data">
      {error && (
        <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      {uploadError && (
        <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          {uploadError}
        </div>
      )}

      <FormField
        id="title"
        label="Titre (max 80 caractères) *"
        type="text"
        required
        maxLength={80}
        value={formData.title}
        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
        error={validationErrors.title}
      />

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <label htmlFor="content" className="block text-sm font-display uppercase text-retro-purple dark:text-white">
            Contenu *
          </label>
          <button
            type="button"
            onClick={() => setShowMarkdownGuide(!showMarkdownGuide)}
            className="text-sm text-retro-pink hover:text-retro-orange transition-colors"
          >
            {showMarkdownGuide ? 'Masquer le guide' : 'Guide de mise en page'}
          </button>
        </div>
        
        {showMarkdownGuide && <MarkdownGuide />}

        <textarea
          id="content"
          required
          rows={15}
          value={formData.content}
          onChange={(e) => setFormData({ ...formData, content: e.target.value })}
          placeholder="Rédigez votre article ici..."
          className={`w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30
                     border-2 ${validationErrors.content ? 'border-red-500' : 'border-retro-purple/10 dark:border-white/10'}
                     text-retro-purple dark:text-white
                     focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink 
                     transition-colors text-sm`}
        />
        {validationErrors.content && (
          <p className="text-sm text-red-500">{validationErrors.content}</p>
        )}
      </div>

      <CategorySelect
        value={formData.category}
        onChange={(value) => setFormData({ ...formData, category: value })}
        error={validationErrors.category}
      />

      <div className="space-y-2">
        <label htmlFor="originalArticleUrl" className="block text-sm font-display uppercase text-retro-purple dark:text-white">
          Lien vers l'article original (optionnel)
        </label>
        <input
          id="originalArticleUrl"
          type="url"
          placeholder="https://..."
          value={formData.originalArticleUrl || ''}
          onChange={(e) => setFormData({ ...formData, originalArticleUrl: e.target.value })}
          className={`w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30
                     border-2 ${validationErrors.originalArticleUrl ? 'border-red-500' : 'border-retro-purple/10 dark:border-white/10'}
                     text-retro-purple dark:text-white
                     focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink 
                     transition-colors`}
        />
        {validationErrors.originalArticleUrl && (
          <p className="text-sm text-red-500">{validationErrors.originalArticleUrl}</p>
        )}
      </div>

      <ImageUploader
        mainImage={formData.mainImage}
        additionalImages={formData.additionalImages}
        onMainImageChange={(url) => {
          setFormData({ ...formData, mainImage: url });
          setUploadError('');
        }}
        onAdditionalImagesChange={(urls) => {
          setFormData({ ...formData, additionalImages: urls });
          setUploadError('');
        }}
        onError={handleImageError}
      />

      <div className="space-y-2">
        <label htmlFor="publishedDate" className="block text-sm font-display uppercase text-retro-purple dark:text-white">
          Date de parution *
        </label>
        <input
          id="publishedDate"
          type="date"
          required
          value={formData.publishedDate}
          onChange={(e) => setFormData({ ...formData, publishedDate: e.target.value })}
          className={`w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30
                     border-2 ${validationErrors.publishedDate ? 'border-red-500' : 'border-retro-purple/10 dark:border-white/10'}
                     text-retro-purple dark:text-white
                     focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink 
                     transition-colors`}
        />
        {validationErrors.publishedDate && (
          <p className="text-sm text-red-500">{validationErrors.publishedDate}</p>
        )}
      </div>

      <div className="flex gap-4">
        <SubmitButton isSubmitting={isSubmitting} />
      </div>
    </form>
  );
}