import React from 'react';
import { ProductCard } from './ProductCard';
import { ProductDescription } from '../../types/shop';

interface ProductSectionProps {
  title: string;
  description: string;
  gradient: string;
  products: {
    name: string;
    price: string;
    image: string;
    colors: string[];
    sizes?: string[];
    isNew?: boolean;
    description?: ProductDescription;
  }[];
  layout: 'large' | 'square';
}

export function ProductSection({ title, description, gradient, products, layout }: ProductSectionProps) {
  return (
    <section className="py-24 px-4">
      <div className="max-w-[1200px] mx-auto">
        <div className="text-center mb-16">
          <h3 className="text-3xl md:text-4xl font-display uppercase mb-6 text-retro-purple dark:text-white retro-shadow">
            {title}
          </h3>
          <p className="text-xl text-retro-purple/80 dark:text-white/80 max-w-2xl mx-auto">
            {description}
          </p>
        </div>
        
        <div className="relative">
          <div className={`absolute inset-0 ${gradient} opacity-10 blur-3xl -z-10`}></div>
          
          {layout === 'large' ? (
            <div className="space-y-12">
              {products.map((product, index) => (
                <div key={index} className="flex flex-col md:flex-row gap-12 max-w-5xl mx-auto">
                  <div className="md:w-2/5">
                    <ProductCard {...product} layout={layout} />
                  </div>
                  
                  {product.description && (
                    <div className="md:w-3/5 space-y-6">
                      <div className="p-2 bg-white/50 dark:bg-retro-purple/30 rounded-lg">
                        <p className="text-lg text-retro-purple/80 dark:text-white/80">
                          {product.description.product}
                        </p>
                      </div>
                      
                      <div className="grid gap-2">
                        <div className="p-2 bg-white/50 dark:bg-retro-purple/30 rounded-lg">
                          <h4 className="font-display text-xl text-retro-purple dark:text-white mb-4">
                            Matière
                          </h4>
                          <p className="text-retro-purple/70 dark:text-white/70">
                            {product.description.fabric}
                          </p>
                        </div>
                        <div className="p-2 bg-white/50 dark:bg-retro-purple/30 rounded-lg">
                          <h4 className="font-display text-xl text-retro-purple dark:text-white mb-4">
                            Fabrication
                          </h4>
                          <p className="text-retro-purple/70 dark:text-white/70">
                            {product.description.manufacturing}
                          </p>
                        </div>
                        <div className="p-2 bg-white/50 dark:bg-retro-purple/30 rounded-lg">
                          <h4 className="font-display text-xl text-retro-purple dark:text-white mb-4">
                            Entretien
                          </h4>
                          <p className="text-retro-purple/70 dark:text-white/70">
                            {product.description.care}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {products.map((product, index) => (
                <ProductCard key={index} {...product} layout={layout} />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}