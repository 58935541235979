import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ThemeProvider } from './context/ThemeContext';
import { ErrorBoundary } from './components/ErrorBoundary';
import { validateEnv } from './utils/env';
import './index.css';

// Validate environment variables before starting the app
validateEnv();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ErrorBoundary>
      <Router>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Router>
    </ErrorBoundary>
  </StrictMode>
);