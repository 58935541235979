import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const EquipmentDetail = () => {
  const { slug } = useParams();

  const equipment = {
    'advance-alpha-7': {
      title: "Advance Alpha 7",
      price: "2 200 €",
      condition: "Comme neuf",
      category: "Voile",
      image: "https://images.unsplash.com/photo-1544551763-46a013bb70d5?auto=format&fit=crop&w=1600&q=80",
      description: `
        L'Alpha 7 est la dernière version de la célèbre voile école d'Advance. 
        Cette voile EN/LTF-A offre une sécurité maximale tout en gardant un excellent 
        potentiel de progression.

        Caractéristiques principales :
        - Surface à plat : 28m²
        - Envergure à plat : 11.8m
        - Allongement à plat : 4.95
        - Nombre de cellules : 39
        - Poids de la voile : 4.9 kg
        - PTV : 70-95 kg

        État : La voile a moins de 20 heures de vol, aucun choc ni réparation.
        Vendue avec sac de portage et sac intérieur d'origine.
      `
    },
    'ozone-rush-5': {
      title: "Ozone Rush 5",
      price: "1 800 €",
      condition: "Bon état",
      category: "Voile",
      image: "https://images.unsplash.com/photo-1495954484750-af469f2f9be5?auto=format&fit=crop&w=1600&q=80",
      description: `
        La Rush 5 est une voile EN-B performante et accessible. Idéale pour les pilotes 
        qui sortent de l'école ou qui cherchent une voile polyvalente pour progresser 
        en cross.

        Caractéristiques principales :
        - Surface à plat : 26.5m²
        - Envergure à plat : 11.5m
        - Allongement à plat : 5.15
        - Nombre de cellules : 47
        - Poids de la voile : 5.1 kg
        - PTV : 65-85 kg

        État : Environ 60 heures de vol, petite réparation sur l'intrados.
        Révision récente effectuée chez Ripair.
      `
    }
  };

  const item = equipment[slug as keyof typeof equipment];

  if (!item) {
    return (
      <div className="min-h-[73vh] pt-36 pb-28 px-4 bg-white dark:bg-retro-purple/95">
        <div className="max-w-[1200px] mx-auto text-center">
          <h2 className="text-4xl md:text-6xl font-display uppercase mb-8 text-retro-purple dark:text-white retro-shadow">
            Équipement non trouvé
          </h2>
          <Link to="/materiel" className="btn-secondary">
            Retour au matériel
          </Link>
        </div>
      </div>
    );
  }

  return (
    <article className="min-h-[73vh] pt-36 pb-28 px-4 bg-white dark:bg-retro-purple/95">
      <div className="max-w-[1200px] mx-auto">
        <div className="flex items-center mb-12">
          <Link 
            to="/materiel"
            className="inline-flex items-center gap-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
          >
            <ArrowLeft className="w-4 h-4" />
            Retour au matériel
          </Link>
        </div>

        <div className="space-y-8">
          <div className="space-y-4">
            <span className="text-sm font-display uppercase text-white bg-retro-blue px-3 py-1 rounded-full inline-block">
              {item.category}
            </span>
            
            <h2 className="text-4xl md:text-6xl font-display uppercase text-retro-purple dark:text-white retro-shadow">
              {item.title}
            </h2>
            
            <div className="flex justify-between items-center">
              <span className="text-2xl font-display text-retro-orange">{item.price}</span>
              <span className="text-retro-purple/80 dark:text-white/80">{item.condition}</span>
            </div>
          </div>

          <div className="relative">
            <img 
              src={item.image} 
              alt={item.title}
              className="w-full h-[440px] object-cover rounded-lg shadow-xl"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent rounded-lg"></div>
          </div>

          <div className="prose prose-lg dark:prose-invert max-w-none">
            {item.description.split('\n\n').map((paragraph, index) => (
              <p key={index} className="mb-6 text-retro-purple/80 dark:text-white/80">
                {paragraph}
              </p>
            ))}
          </div>

          <div className="flex gap-4">
            <button className="btn-primary flex-1">
              Contacter le vendeur
            </button>
            <button className="btn-secondary flex-1">
              Ajouter aux favoris
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default EquipmentDetail;