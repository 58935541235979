import React from 'react';
import { RichText } from '../../../components/ui/RichText';
import { ImageGallery } from '../../../components/ui/ImageGallery';
import { News } from '../newsTypes';
import { formatDateTime } from '../../../utils/dates';
import { NewsHeader } from './NewsHeader';
import { NewsImage } from './NewsImage';
import { NewsActions } from './NewsActions';

interface NewsContentProps {
  news: News;
}

export function NewsContent({ news }: NewsContentProps) {
  return (
    <div className="max-w-[800px] mx-auto space-y-8">
      <div>
        <span className="text-sm font-display uppercase text-white bg-retro-orange px-3 py-1 rounded-full inline-block mb-4">
          {news.category}
        </span>

        <div className="flex justify-between items-center text-sm mb-6">
          <div className="flex items-center gap-2">
            <time className="text-retro-purple/60 dark:text-white/60">
              {news.publishedDate ? formatDateTime(news.publishedDate) : formatDateTime(news.createdAt)}
            </time>
            <span className="text-retro-purple/40 dark:text-white/40">•</span>
            <span className="text-retro-orange">{news.authorName}</span>
          </div>
        </div>

        <h1 className="text-4xl font-display uppercase text-retro-purple dark:text-white">
          {news.title}
        </h1>
      </div>

      {news.mainImage && (
        <div className="relative w-full max-w-[800px] mx-auto flex justify-center">
          <img 
            src={news.mainImage} 
            alt={news.title}
            className="max-w-full max-h-[600px] object-contain rounded-lg shadow-xl"
          />
        </div>
      )}

      <RichText content={news.content} />

      {news.additionalImages && news.additionalImages.length > 0 && (
        <ImageGallery images={news.additionalImages} alt={news.title} />
      )}
      <NewsActions
        title={news.title}
        content={news.content}
        originalArticleUrl={news.originalArticleUrl}
      />
    </div>
  );
}