import React from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useNews } from '../features/news/hooks/useNews';
import { NewsList } from '../features/news/components/NewsList';

export default function SearchPage() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') || '';
  const { news, isLoading, error } = useNews();

  const filteredNews = news.filter(item => {
    const searchTerm = query.toLowerCase();
    return (
      item.title.toLowerCase().includes(searchTerm) ||
      item.content.toLowerCase().includes(searchTerm) ||
      item.category.toLowerCase().includes(searchTerm)
    );
  });

  return (
    <div>
      <section className="py-16 px-4 bg-retro-pink/10 dark:bg-retro-purple/10">
        <div className="max-w-[1200px] mx-auto">
          <Link
            to="/actualites"
            className="inline-flex items-center gap-2 text-retro-purple dark:text-white mb-8 hover:opacity-80 transition-opacity"
          >
            <ArrowLeft className="w-5 h-5" />
            Retour aux actualités
          </Link>

          <h2 className="text-4xl md:text-6xl font-display uppercase mb-6 text-retro-purple dark:text-white retro-shadow">
            Résultats de recherche
          </h2>
          
          <p className="text-xl text-retro-purple/80 dark:text-white/80">
            {filteredNews.length} résultat{filteredNews.length !== 1 ? 's' : ''} pour "{query}"
          </p>
        </div>
      </section>

      <section className="py-16 px-4">
        <div className="max-w-[1200px] mx-auto">
          <NewsList 
            news={filteredNews}
            isLoading={isLoading}
            error={error}
          />
        </div>
      </section>
    </div>
  );
}