import { supabase } from './supabase';
import { logger } from '../utils/debug';

const STORAGE_BUCKET = 'news_images';

/**
 * Upload an image to Supabase Storage
 * @param file The file to upload
 * @returns The public URL of the uploaded file
 * @throws Error if upload fails
 */
export async function uploadImage(file: File): Promise<string> {
  try {
    const fileName = `${Date.now()}-${file.name.replace(/[^a-zA-Z0-9.]/g, '_')}`;
    
    const { data, error: uploadError } = await supabase.storage
      .from(STORAGE_BUCKET)
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false,
        contentType: file.type
      });

    if (uploadError) {
      logger.error('Upload error:', uploadError);
      throw new Error('Erreur lors de l\'upload de l\'image');
    }

    if (!data?.path) {
      throw new Error('Chemin de fichier manquant après l\'upload');
    }

    const { data: { publicUrl } } = supabase.storage
      .from(STORAGE_BUCKET)
      .getPublicUrl(data.path);

    return publicUrl;
  } catch (error) {
    logger.error('Image upload error:', error);
    throw error;
  }
}