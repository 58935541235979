export function handleApiError(error: unknown): string {
  if (error instanceof Error) {
    if (error.message.includes('storage/object-too-large')) {
      return 'L\'image est trop volumineuse. Taille maximum : 5MB';
    }
    if (error.message.includes('already registered')) {
      return 'Un compte existe déjà avec cette adresse email';
    }
    if (error.message.includes('invalid login credentials')) {
      return 'Email ou mot de passe incorrect';
    }
    if (error.message.includes('Email not confirmed')) {
      return 'Veuillez confirmer votre email avant de vous connecter';
    }
    if (error.message.includes('network')) {
      return 'Erreur de connexion. Veuillez vérifier votre connexion internet';
    }
    return error.message;
  }
  return 'Une erreur inattendue est survenue. Veuillez réessayer.';
}