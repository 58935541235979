import React from 'react';

export function MarkdownGuide() {
  return (
    <div className="p-4 bg-retro-purple/5 dark:bg-white/5 rounded-lg space-y-4 text-sm">
      <h4 className="font-display text-retro-purple dark:text-white">Guide de mise en page</h4>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h5 className="font-medium text-retro-purple dark:text-white mb-2">Texte</h5>
          <ul className="space-y-2 text-retro-purple/80 dark:text-white/80">
            <li><code className="text-retro-orange"># Titre</code> pour un titre principal</li>
            <li><code className="text-retro-orange">## Sous-titre</code> pour un sous-titre</li>
            <li><code className="text-retro-orange">**texte**</code> pour du <strong>texte en gras</strong></li>
            <li><code className="text-retro-orange">*texte*</code> pour du <em>texte en italique</em></li>
          </ul>
        </div>

        <div>
          <h5 className="font-medium text-retro-purple dark:text-white mb-2">Listes</h5>
          <ul className="space-y-2 text-retro-purple/80 dark:text-white/80">
            <li><code className="text-retro-orange">- élément</code> pour une liste à puces</li>
            <li><code className="text-retro-orange">1. élément</code> pour une liste numérotée</li>
          </ul>
        </div>

        <div>
          <h5 className="font-medium text-retro-purple dark:text-white mb-2">Liens et citations</h5>
          <ul className="space-y-2 text-retro-purple/80 dark:text-white/80">
            <li><code className="text-retro-orange">[texte](url)</code> pour un lien</li>
            <li><code className="text-retro-orange">{`> citation`}</code> pour une citation</li>
          </ul>
        </div>
      </div>
    </div>
  );
}