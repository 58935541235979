import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { getNewsById, getAdjacentNews } from '../../features/news/api/news';
import type { News } from '../../features/news/types';
import { NewsContent } from '../../features/news/components/NewsContent';
import { NewsNavigation } from '../../features/news/components/NewsNavigation';

const NewsDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [news, setNews] = useState<News | null>(null);
  const [adjacentArticles, setAdjacentArticles] = useState<{
    previous: News | null;
    next: News | null;
  }>({ previous: null, next: null });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) throw new Error('ID de l\'article manquant');
        setIsLoading(true);
        
        const [articleData, adjacentData] = await Promise.all([
          getNewsById(id),
          getAdjacentNews(id)
        ]);
        
        setNews(articleData);
        setAdjacentArticles(adjacentData);
      } catch (err) {
        console.error('Error fetching article:', err);
        setError(err instanceof Error ? err.message : 'Erreur lors du chargement de l\'article');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (isLoading) {
    return (
      <div className="min-h-[73vh] pt-36 pb-28 px-4 bg-white dark:bg-retro-purple/95 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-retro-pink"></div>
      </div>
    );
  }

  if (error || !news) {
    return (
      <div className="min-h-[73vh] pt-36 pb-28 px-4 bg-white dark:bg-retro-purple/95">
        <div className="max-w-[1200px] mx-auto text-center">
          <h2 className="text-4xl md:text-6xl font-display text-retro-purple dark:text-white mb-8 retro-shadow">
            Article non trouvé
          </h2>
          <Link to="/actualites" className="btn-secondary">
            Retour aux actualités
          </Link>
        </div>
      </div>
    );
  }

  return (
    <article className="min-h-[73vh] pt-36 pb-28 px-4 bg-white dark:bg-retro-purple/95">
      <div className="max-w-[1200px] mx-auto">
        <div className="mb-12">
          <Link 
            to="/actualites"
            className="inline-flex items-center gap-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
          >
            <ArrowLeft className="w-4 h-4" />
            Retour aux actualités
          </Link>
          <div className="h-px bg-retro-purple/10 dark:bg-white/10 mt-8"></div>
        </div>

        <NewsContent news={news} />
        
        <NewsNavigation
          previousNews={adjacentArticles.previous}
          nextNews={adjacentArticles.next}
        />
      </div>
    </article>
  );
};

export default NewsDetail;