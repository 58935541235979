import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Search as SearchIcon, X, Moon, Sun, UserCircle2, LogOut } from 'lucide-react';
import { useTheme } from '../../context/ThemeContext';
import { useAuth } from '../../context/AuthContext';
import { NavDropdown } from '../navigation/NavDropdown';
import { Search } from '../ui/Search';
import { MobileNavDropdown } from '../navigation/MobileNavDropdown';
import { navigationItems } from '../navigation/navigationConfig';
import { PageContainer } from './PageContainer';

const MainNavigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isDark, toggleTheme } = useTheme();
  const { state: { isAuthenticated, user }, logout } = useAuth();
  const location = useLocation();

  const isActive = (path: string) => location.pathname === path;
  const isActiveDropdown = (paths: string[]) => paths.some(path => location.pathname.startsWith(path));

  return (
    <>
      <nav className="fixed w-full z-50 bg-white/80 dark:bg-retro-purple/95 backdrop-blur-md border-b border-retro-purple/10">
        <PageContainer>
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center">
                <span className="text-2xl font-display uppercase tracking-wider text-retro-purple dark:text-white">
                  Vola<span className="rainbow-text">volo</span>
                </span>
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center justify-center flex-1">
              <div className="flex items-center space-x-8">
                <NavDropdown
                  label={navigationItems.actualites.label}
                  items={navigationItems.actualites.items}
                  isActive={isActiveDropdown(['/actualites', '/iframe'])}
                />
                <NavDropdown
                  label={navigationItems.materiel.label}
                  items={navigationItems.materiel.items}
                  isActive={isActiveDropdown(['/materiel'])}
                />
                <Link
                  to={navigationItems.boutique.href}
                  className={`text-retro-purple dark:text-white hover:text-retro-pink transition-colors font-display uppercase tracking-wider ${
                    isActive(navigationItems.boutique.href) ? 'text-retro-pink' : ''
                  }`}
                >
                  {navigationItems.boutique.label}
                </Link>
              </div>
            </div>

            {/* Desktop Actions */}
            <div className="hidden md:flex items-center gap-4">
              <Search />
              <div className="relative group">
                {isAuthenticated ? (
                  <button
                    className="p-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors flex items-center gap-2"
                    aria-label="Menu utilisateur"
                  >
                    <UserCircle2 className="w-5 h-5" />
                    <span className="text-sm">{user?.firstName || 'Utilisateur'}</span>
                  </button>
                ) : (
                  <button
                    className="p-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
                    aria-label="Menu utilisateur"
                  >
                    <UserCircle2 className="w-5 h-5" />
                  </button>
                )}
                <div className="absolute right-0 top-full pt-2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 min-w-[200px]">
                  <div className="bg-white dark:bg-retro-purple shadow-lg border border-retro-purple/10 dark:border-white/10 rounded-lg p-2 relative">
                    <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-2 w-3 h-3 bg-white dark:bg-retro-purple rotate-45 border-l border-t border-retro-purple/10 dark:border-white/10"></div>
                    {isAuthenticated ? (
                      <>
                        <Link
                          to="/profile"
                          className="block px-4 py-3 text-sm text-retro-purple dark:text-white hover:text-retro-pink dark:hover:text-retro-pink transition-colors rounded-md hover:bg-retro-purple/5 dark:hover:bg-white/5"
                        >
                          Mon profil
                        </Link>
                        <button
                          onClick={logout}
                          className="w-full text-left px-4 py-3 text-sm text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 transition-colors rounded-md hover:bg-red-50 dark:hover:bg-red-900/10 flex items-center gap-2"
                        >
                          <LogOut className="w-4 h-4" />
                          Déconnexion
                        </button>
                      </>
                    ) : (
                      <>
                        <Link
                          to="/auth"
                          className="block px-4 py-3 text-sm text-retro-purple dark:text-white hover:text-retro-pink dark:hover:text-retro-pink transition-colors rounded-md hover:bg-retro-purple/5 dark:hover:bg-white/5"
                        >
                          Se connecter
                        </Link>
                        <Link
                          to="/auth?register=true"
                          className="block px-4 py-3 text-sm text-retro-purple dark:text-white hover:text-retro-pink dark:hover:text-retro-pink transition-colors rounded-md hover:bg-retro-purple/5 dark:hover:bg-white/5"
                        >
                          S'inscrire
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={toggleTheme}
                className="p-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
                aria-label="Toggle theme"
              >
                {isDark ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
              </button>
            </div>

            {/* Mobile Actions */}
            <div className="flex md:hidden items-center gap-2">
              <Link
                to={isAuthenticated ? "/profile" : "/auth"}
                className="p-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
                aria-label="Menu utilisateur"
              >
                <UserCircle2 className="w-5 h-5" />
              </Link>
              <button
                onClick={toggleTheme}
                className="p-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
                aria-label="Toggle theme"
              >
                {isDark ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
              </button>
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
                aria-label="Toggle menu"
              >
                {isMenuOpen ? <X className="w-5 h-5" /> : <Menu className="w-5 h-5" />}
              </button>
            </div>
          </div>
        </PageContainer>
      </nav>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <div 
          className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40 md:hidden"
          onClick={() => setIsMenuOpen(false)}
        />
      )}

      {/* Mobile Menu */}
      <div 
        className={`fixed top-0 right-0 h-full w-80 bg-white dark:bg-retro-purple shadow-2xl transform transition-transform duration-300 ease-in-out z-50 md:hidden ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-col h-full pt-20 px-4">
          {/* Close Button */}
          <button
            onClick={() => setIsMenuOpen(false)}
            className="absolute top-4 right-4 p-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
            aria-label="Close menu"
          >
            <X className="w-6 h-6" />
          </button>

          {/* Search in Mobile Menu */}
          <div className="mb-6">
            <div className="relative">
              <input
                type="search"
                placeholder="Rechercher..."
                className="w-full pl-10 pr-4 py-2 rounded-lg bg-white dark:bg-retro-purple/30 
                         border-2 border-retro-purple/10 dark:border-white/10 
                         text-retro-purple dark:text-white
                         focus:outline-none focus:border-retro-pink"
              />
              <SearchIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-retro-purple/60 dark:text-white/60" />
            </div>
          </div>

          {/* Mobile Navigation Links */}
          <div className="space-y-6">
            <Link 
              to="/"
              onClick={() => setIsMenuOpen(false)}
              className={`block text-lg font-display uppercase tracking-wider ${
                isActive('/') 
                  ? 'text-retro-pink' 
                  : 'text-retro-purple dark:text-white hover:text-retro-pink transition-colors'
              }`}
            >
              Accueil
            </Link>
            
            <MobileNavDropdown
              label={navigationItems.actualites.label}
              items={navigationItems.actualites.items}
              isActive={isActiveDropdown(['/actualites', '/iframe'])}
              onItemClick={() => setIsMenuOpen(false)}
            />
            
            <MobileNavDropdown
              label={navigationItems.materiel.label}
              items={navigationItems.materiel.items}
              isActive={isActiveDropdown(['/materiel'])}
              onItemClick={() => setIsMenuOpen(false)}
            />
            
            <Link
              to={navigationItems.boutique.href}
              onClick={() => setIsMenuOpen(false)}
              className={`block text-lg font-display uppercase tracking-wider ${
                isActive(navigationItems.boutique.href)
                  ? 'text-retro-pink'
                  : 'text-retro-purple dark:text-white hover:text-retro-pink transition-colors'
              }`}
            >
              {navigationItems.boutique.label}
            </Link>
            
            {isAuthenticated && (
              <button
                onClick={() => {
                  logout();
                  setIsMenuOpen(false);
                }}
                className="w-full text-left flex items-center gap-2 text-lg font-display uppercase tracking-wider text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 transition-colors"
              >
                <LogOut className="w-5 h-5" />
                Déconnexion
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNavigation;