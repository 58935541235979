import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { cn } from '../../utils/cn';

interface RichTextProps {
  content: string;
  className?: string;
}

export function RichText({ content, className }: RichTextProps) {
  return (
    <ReactMarkdown
      className={cn(
        'prose prose-lg dark:prose-invert max-w-none',
        'prose-headings:font-display prose-headings:uppercase prose-headings:text-retro-purple dark:prose-headings:text-white',
        'prose-p:text-retro-purple/80 dark:prose-p:text-white/80',
        'prose-a:text-retro-pink hover:prose-a:text-retro-orange transition-colors',
        'prose-strong:text-retro-purple dark:prose-strong:text-white',
        'prose-code:text-retro-orange dark:prose-code:text-retro-pink',
        'prose-pre:bg-retro-purple/5 dark:prose-pre:bg-white/5',
        'prose-blockquote:border-retro-pink prose-blockquote:text-retro-purple/60 dark:prose-blockquote:text-white/60',
        'prose-img:rounded-lg prose-img:shadow-lg',
        className
      )}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        a: ({ node, ...props }) => (
          <a {...props} target="_blank" rel="noopener noreferrer" />
        ),
        img: ({ node, ...props }) => (
          <img {...props} loading="lazy" className="w-full h-auto" />
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
}