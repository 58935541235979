import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../utils/cn';

interface NavDropdownItem {
  label: string;
  href: string;
}

interface NavDropdownProps {
  label: string;
  items: NavDropdownItem[];
  isActive?: boolean;
}

export function NavDropdown({ label, items, isActive }: NavDropdownProps) {
  return (
    <div className="relative group">
      <button
        className={cn(
          "inline-flex items-center gap-1 py-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors font-display uppercase tracking-wider group-hover:text-retro-pink",
          isActive && "text-retro-pink"
        )}
      >
        {label}
        <ChevronDown className="w-4 h-4 transition-transform duration-300 group-hover:rotate-180" />
      </button>
      
      <div className="absolute left-1/2 -translate-x-1/2 top-full pt-2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 min-w-[220px]">
        <div className="bg-white dark:bg-retro-purple shadow-lg border border-retro-purple/10 dark:border-white/10 rounded-lg p-2 relative">
          <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-2 w-3 h-3 bg-white dark:bg-retro-purple rotate-45 border-l border-t border-retro-purple/10 dark:border-white/10"></div>
          {items.map((item, index) => (
            <Link
              key={index}
              to={item.href}
              className="block px-4 py-3 text-sm text-retro-purple dark:text-white hover:text-retro-pink dark:hover:text-retro-pink transition-colors rounded-md hover:bg-retro-purple/5 dark:hover:bg-white/5"
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}