import React from 'react';

export default function NewsAdditionPage() {
  return (
    <div className="min-h-[73vh] bg-white dark:bg-retro-purple/95">
      {/* Hero Section */}
      <section className="pt-36 pb-20 px-4 relative overflow-hidden bg-gradient-to-b from-white dark:from-retro-purple to-retro-purple/5 dark:to-retro-purple/20">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,#FF69B4_0%,transparent_70%)] opacity-10"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,#00B4D8_0%,transparent_70%)] opacity-10"></div>
        
        <div className="max-w-[1200px] mx-auto relative z-10">
          <h2 className="text-4xl md:text-6xl font-display uppercase mb-6 leading-tight text-retro-purple dark:text-white retro-shadow">
            Partagez en publiant librement
          </h2>
          
          <p className="text-xl text-retro-purple/80 dark:text-white/80 max-w-2xl mb-8">
            L'information et l'actualité renseignées par ceux qui la vivent. Les pros, les officiels et les passionnés ont désormais leur cahier dédié.
          </p>
        </div>
      </section>

      {/* Who can Write Section */}
      <section className="py-16 px-4 bg-white dark:bg-retro-purple/90">
        <div className="max-w-[1200px] mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div>
              <h3 className="text-3xl md:text-4xl font-display uppercase mb-8 text-retro-purple dark:text-white retro-shadow">
                Qui peut écrire ?
              </h3>
              <h4 className="font-display text-xl text-retro-purple dark:text-white mb-4">
                Tout le monde
              </h4>
              <p className="text-base leading-relaxed text-retro-purple/80 dark:text-white/80">
                Pas besoin de maitriser la césure à l'hémistiche ni d'avoir l'occiput au complet, nous apprécions la diversité des voix et des regards portés. 
                Que vous soyez un professionnel du parapente ou un simple passionné, vous pouvez partager vos connaissances ou vos expériences avec la communauté. 
                Par respect et par soucis de probité, on vous demandera juste de préciser à quel titre vous publiez.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Why to Write Section */}
      <section className="py-16 px-4 bg-white dark:bg-retro-purple/90">
        <div className="max-w-[1200px] mx-auto">
          <h3 className="text-3xl md:text-4xl font-display uppercase mb-8 text-retro-purple dark:text-white retro-shadow">
            Pourquoi écrire ?
          </h3>
          <div className="relative flex flex-col gap-8">
            {/* Connecting Line */}
            <div className="absolute left-[28px] top-12 bottom-12 w-1 bg-gradient-to-b from-retro-pink via-retro-orange to-retro-blue opacity-20 md:left-1/2 md:-translate-x-0.5"></div>
            
            <div className="space-y-8">
              {/* Passion */}
              <div className="relative flex items-center gap-8 md:justify-between group">
                <div className="w-14 h-14 rounded-full bg-gradient-to-br from-retro-pink to-retro-orange flex items-center justify-center z-10 shrink-0 md:order-2">
                      <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                      </svg>
                </div>
                <div className="flex-1 md:text-right md:order-1">
                  <div className="bg-white dark:bg-retro-purple/50 rounded-lg p-8">
                    <h4 className="font-display text-xl text-retro-pink mb-4 md:text-right">PARTAGEZ VOTRE PASSION</h4>
                    <p className="text-base leading-relaxed text-retro-purple/80 dark:text-white/80">
                      Faites-nous découvrir "votre" amour pour le PET volant. C'est intéressant de découvrir ce qu'on a en commun et de différent dans une communauté qui a le même intérêt.
                    </p>
                  </div>
                </div>
                <div className="hidden md:block flex-1 md:order-3"></div>
              </div>

              {/* Inspiration */}
              <div className="relative flex items-center gap-8 md:justify-between group">
                <div className="w-14 h-14 rounded-full bg-gradient-to-br from-retro-orange to-retro-yellow flex items-center justify-center z-10 shrink-0 md:order-2">
                      <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                      </svg>
                </div>
                <div className="hidden md:block flex-1 md:order-1"></div>
                <div className="flex-1 md:order-3">
                  <div className="bg-white dark:bg-retro-purple/50 rounded-lg p-8">
                    <h4 className="font-display text-xl text-retro-orange mb-4">INSPIREZ LES AUTRES</h4>
                    <p className="text-base leading-relaxed text-retro-purple/80 dark:text-white/80">
                      Vos histoires et conseils peuvent servir d'enseignement ou inspirer d'autres pratiquants. On apprécie de lire les nouveaux comme ceux qui sont expérimentés...
                    </p>
                  </div>
                </div>
              </div>

              {/* Community */}
              <div className="relative flex items-center gap-8 md:justify-between group">
                <div className="w-14 h-14 rounded-full bg-gradient-to-br from-retro-blue to-retro-purple flex items-center justify-center z-10 shrink-0 md:order-2">
                      <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                </div>
                <div className="flex-1 md:text-right md:order-1">
                  <div className="bg-white dark:bg-retro-purple/50 rounded-lg p-8">
                    <h4 className="font-display text-xl text-retro-blue mb-4 md:text-right">FAITES VIVRE LA COMMUNAUTÉ</h4>
                    <p className="text-base leading-relaxed text-retro-purple/80 dark:text-white/80">
                      En partageant, vous participez à construire une communauté plus forte et plus informée.
                    </p>
                  </div>
                </div>
                <div className="hidden md:block flex-1 md:order-3"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* How to Write Section */}
      <section className="py-16 px-4 bg-white dark:bg-retro-purple/90">
        <div className="max-w-[1200px] mx-auto">
          <h3 className="text-4xl md:text-5xl font-display uppercase mb-12 text-retro-purple dark:text-white retro-shadow text-center">
            Comment écrire ?
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="group relative">
              <div className="absolute inset-0 bg-gradient-to-r from-retro-pink to-retro-orange rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-500"></div>
              <div className="relative bg-white dark:bg-retro-purple/50 rounded-lg p-6">
                <div className="p-6">
                <h4 className="text-xl font-display uppercase mb-4 text-retro-purple dark:text-white">
                Respect et responsabilité
                </h4>
                <p className="text-retro-purple/80 dark:text-white/80">
                Nous encourageons tous les auteurs à être respectueux et responsables dans leurs écrits. Vous signez de votre nom - à priori en disant ça le reste se passe d'explication. Assurez-vous que vos articles sont bienveillants, informatifs et constructifs. Vérifiez la véracité des informations que vous partagez.
                </p>
                </div>
              </div>
            </div>
            
            <div className="group relative">
              <div className="absolute inset-0 bg-gradient-to-r from-retro-blue to-retro-purple rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-500"></div>
              <div className="relative bg-white dark:bg-retro-purple/50 rounded-lg p-6">
                <div className="p-6">
                <h4 className="text-xl font-display uppercase mb-4 text-retro-purple dark:text-white">
                Format et style
                </h4>
                <p className="text-retro-purple/80 dark:text-white/80">
                Structurez votre article de manière claire et cohérente. Utilisez des paragraphes, des sous-titres et de la ponctuation pour faciliter la lecture. Le style vous est propre, l'orthographe nous est commune. Faites-vous relire par un proche qui sera touché d'avoir la primauté et saura vous dire où la cédille se met.
                </p>
                </div>
              </div>
            </div>
            
            <div className="group relative md:col-span-2">
              <div className="absolute inset-0 bg-gradient-to-r from-retro-green to-retro-blue rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-500"></div>
              <div className="relative bg-white dark:bg-retro-purple/50 rounded-lg p-6">
                <div className="p-6">
                <h4 className="text-xl font-display uppercase mb-4 text-retro-purple dark:text-white">
                Langage et originalité
                </h4>
                <p className="text-retro-purple/80 dark:text-white/80">
                Utilisez un langage approprié et interdisez-vous les propos offensants ou discriminatoires (même envers les arbres mal placés). Nous valorisons l'originalité et ne sommes pas contre la créativité. Pour Icare et sa mémoire, évitons le plagiat et les IA qui quoi qu'on en dise n'iront jamais dans les nuages.
                </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center text-retro-purple/60 dark:text-white/60 mt-8 italic">
            Faites-nous lire et voir ce que vous aimez - tout simplement!
          </p>
        </div>
      </section>

      {/* Iframe Section */}
      <section className="py-16 px-4">
        <div className="max-w-[1200px] mx-auto">
          <div className="rounded-lg overflow-hidden shadow-xl bg-white dark:bg-retro-purple/50">
            <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
            <iframe 
              className="airtable-embed airtable-dynamic-height w-full"
              src="https://airtable.com/embed/appc7OG9HlvnJLe1d/shr9ZgXqtPiG7xmyx"
              frameBorder="0"
              height="2254"
              style={{ background: 'transparent', border: '1px solid rgba(32, 9, 67, 0.1)' }}
              title="Article Submission Form"
            />
          </div>
        </div>
      </section>
    </div>
  );
}