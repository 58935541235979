import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LoginCredentials } from '../../types/auth';

export default function LoginForm() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = React.useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const credentials: LoginCredentials = {
      email: formData.get('email') as string,
      password: formData.get('password') as string,
    };

    try {
      await login(credentials);
      navigate('/');
    } catch (err) {
      setError('Échec de la connexion. Veuillez réessayer.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}
      
      <div>
        <label htmlFor="email" className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
          Email
        </label>
        <input
          id="email"
          name="email"
          type="email"
          required
          className="w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 border-2 border-retro-purple/10 dark:border-white/10 
                   text-retro-purple dark:text-white
                   focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink transition-colors"
        />
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
          Mot de passe
        </label>
        <input
          id="password"
          name="password"
          type="password"
          required
          className="w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 border-2 border-retro-purple/10 dark:border-white/10 
                   text-retro-purple dark:text-white
                   focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink transition-colors"
        />
      </div>

      <button
        type="submit"
        className="w-full btn-primary"
      >
        Se connecter
      </button>
    </form>
  );
}