import React from 'react';
import { Link } from 'react-router-dom';
import type { News } from '../newsTypes';
import { formatRelativeDate } from '../../../utils/dates';
import { RichText } from '../../../components/ui/RichText';

interface NewsListProps {
  news: News[];
  isLoading: boolean;
  error: string | null;
}

const categoryColors: Record<string, string> = {
  'Compétition': 'bg-retro-red',
  'Evenement': 'bg-retro-orange',
  'Localité/Site': 'bg-retro-blue',
  'Matériel': 'bg-retro-green',
  'Médias': 'bg-retro-pink',
  'Personnalité': 'bg-retro-purple',
  'Récit': 'bg-retro-yellow',
  'Record': 'bg-retro-red'
};

function truncateContent(content: string, maxLength: number = 160): string {
  const strippedContent = content.replace(/[#*`_~\[\]]/g, '');
  if (strippedContent.length <= maxLength) return content;
  return strippedContent.slice(0, maxLength).trim() + '...';
}

export function NewsList({ news, isLoading, error }: NewsListProps) {
  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        {[1, 2, 3].map((n) => (
          <div key={n} className="card animate-pulse">
            <div className="h-56 bg-retro-purple/10 dark:bg-white/10"></div>
            <div className="p-6 space-y-4">
              <div className="h-4 bg-retro-purple/10 dark:bg-white/10 rounded w-1/4"></div>
              <div className="h-6 bg-retro-purple/10 dark:bg-white/10 rounded w-3/4"></div>
              <div className="h-4 bg-retro-purple/10 dark:bg-white/10 rounded w-full"></div>
              <div className="h-4 bg-retro-purple/10 dark:bg-white/10 rounded w-2/3"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600">{error}</p>
        <button 
          onClick={() => window.location.reload()}
          className="mt-4 btn-secondary"
        >
          Réessayer
        </button>
      </div>
    );
  }

  if (!news.length) {
    return (
      <div className="text-center py-12">
        <p className="text-retro-purple/80 dark:text-white/80">
          Aucune actualité n'a encore été publiée.
        </p>
        <Link 
          to="/news-addition"
          className="mt-4 btn-primary inline-block"
        >
          Publier la première actualité
        </Link>
      </div>
    );
  }
  
  // Sort news by published date (most recent first)
  const sortedNews = [...news].sort((a, b) => 
    new Date(b.publishedDate).getTime() - new Date(a.publishedDate).getTime()
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
      {sortedNews.map((item) => (
        <Link 
          key={item.id}
          to={`/actualites/${item.id}`}
          className="card dark:bg-retro-purple/50 dark:border-retro-pink/20 overflow-hidden flex flex-col hover:scale-[1.02] transition-transform duration-300"
        >
          <div className="relative h-56 overflow-hidden">
            {item.mainImage ? (
              <img 
                src={item.mainImage} 
                alt={item.title}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-retro-purple/10 dark:bg-white/10 flex items-center justify-center">
                <span className="text-retro-purple/40 dark:text-white/40">
                  Pas d'image
                </span>
              </div>
            )}
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent"></div>
            <div className="absolute bottom-4 left-4">
              <span className={`text-xs font-display uppercase text-white px-3 py-1 rounded-full shadow-lg ${categoryColors[item.category]}`}>
                {item.category}
              </span>
            </div>
          </div>
          
          <div className="p-6 flex-1 flex flex-col">
            <div className="mb-4">
              <time className="text-sm text-retro-purple/60 dark:text-white/60">
                {formatRelativeDate(item.createdAt)}
              </time>
            </div>
            <h3 className="text-xl font-display uppercase mb-3 text-retro-purple dark:text-white">
              {item.title}
            </h3>
            <div className="text-base text-retro-purple/80 dark:text-white/80 flex-1 prose prose-sm dark:prose-invert max-w-none">
              <RichText content={truncateContent(item.content)} />
            </div>
            <div className="flex justify-between items-center mt-4">
              <span className="text-xs font-display uppercase text-retro-orange">
                {item.authorName}
              </span>
              <span className="text-retro-purple/60 dark:text-white/60 text-sm">
                Lire la suite →
              </span>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}