import React from 'react';

interface FormFieldProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string;
  error?: string;
  type: string;
  rows?: number;
}

export function FormField({ label, error, type, rows, ...props }: FormFieldProps) {
  const inputClasses = `
    w-full px-4 py-3 rounded-lg 
    bg-white dark:bg-retro-purple/30 
    border-2 border-retro-purple/10 dark:border-white/10 
    text-retro-purple dark:text-white
    focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink 
    transition-colors
    ${error ? 'border-red-500' : ''}
  `;

  return (
    <div>
      <label htmlFor={props.id} className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
        {label}
      </label>
      {type === 'textarea' ? (
        <textarea
          {...props as React.TextareaHTMLAttributes<HTMLTextAreaElement>}
          className={inputClasses}
          rows={rows}
        />
      ) : (
        <input
          {...props as React.InputHTMLAttributes<HTMLInputElement>}
          type={type}
          className={inputClasses}
        />
      )}
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
}