import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../utils/cn';

interface MobileNavDropdownItem {
  label: string;
  href: string;
}

interface MobileNavDropdownProps {
  label: string;
  items: MobileNavDropdownItem[];
  isActive?: boolean;
  onItemClick: () => void;
}

export function MobileNavDropdown({ label, items, isActive, onItemClick }: MobileNavDropdownProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cn(
          "flex items-center justify-between w-full py-2 text-lg font-display uppercase tracking-wider",
          isActive ? "text-retro-pink" : "text-retro-purple dark:text-white hover:text-retro-pink"
        )}
      >
        {label}
        <ChevronDown className={cn(
          "w-5 h-5 transition-transform duration-300",
          isOpen && "rotate-180"
        )} />
      </button>
      
      <div className={cn(
        "space-y-1 pl-6 overflow-hidden transition-all duration-300",
        isOpen ? "max-h-48 opacity-100 mt-3" : "max-h-0 opacity-0"
      )}>
        {items.map((item, index) => (
          <Link
            key={index}
            to={item.href}
            onClick={onItemClick}
            className="block py-3 text-base text-retro-purple/80 dark:text-white/80 hover:text-retro-pink transition-colors border-l-2 border-retro-purple/10 dark:border-white/10 pl-4 hover:border-retro-pink"
          >
            {item.label}
          </Link>
        ))}
      </div>
    </div>
  );
}