export interface News {
  id: string;
  title: string;
  content: string;
  category: NewsCategory;
  mainImage?: string;
  additionalImages?: string[];
  authorName: string;
  authorType: string;
  createdAt: string;
  publishedDate: string | null;
  originalArticleUrl?: string;
}

export interface CreateNewsData {
  title: string;
  content: string;
  category: NewsCategory;
  mainImage?: string;
  publishedDate: string;
  originalArticleUrl?: string;
  additionalImages?: string[];
}

export type NewsCategory = 
  | 'Compétition'
  | 'Evenement'
  | 'Localité/Site'
  | 'Matériel'
  | 'Médias'
  | 'Personnalité'
  | 'Récit'
  | 'Record';

export const NEWS_CATEGORIES: NewsCategory[] = [
  'Compétition',
  'Evenement',
  'Localité/Site',
  'Matériel',
  'Médias',
  'Personnalité',
  'Récit',
  'Record'
];