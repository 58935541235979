import React from 'react';
import { ShareButton } from '../../../components/ui/ShareButton';

interface NewsActionsProps {
  title: string;
  content: string;
  originalArticleUrl?: string;
}

export function NewsActions({ title, content, originalArticleUrl }: NewsActionsProps) {
  return (
    <div className="flex flex-col items-center gap-6">
      <ShareButton 
        title={title}
        text={content.slice(0, 160) + '...'}
        variant="primary"
        label="Partager l'actualité"
        className="w-auto min-w-[200px]"
      />
      {originalArticleUrl && (
        <a
          href={originalArticleUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-secondary inline-flex items-center gap-2 w-auto min-w-[200px] justify-center"
        >
          Lien parution originale
          <span className="text-xs opacity-60 ml-1">
            ({new URL(originalArticleUrl).hostname})
          </span>
        </a>
      )}
    </div>
  );
}