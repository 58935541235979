import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ArrowRight, PlusCircle } from 'lucide-react';
import { ConstructionModal } from '../ui/ConstructionModal';
import { PageContainer } from '../layout/PageContainer';

const EquipmentPageGrid = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    if (!isHomePage) {
      setIsModalOpen(true);
    }
  }, [isHomePage]);

  const listings = [
    {
      id: 1,
      title: "Advance Alpha 7",
      price: "2 200 €",
      condition: "Comme neuf",
      category: "Voile",
      image: "https://images.unsplash.com/photo-1544551763-46a013bb70d5?auto=format&fit=crop&w=600&q=80",
      slug: "advance-alpha-7"
    },
    {
      id: 2,
      title: "Ozone Rush 5",
      price: "1 800 €",
      condition: "Bon état",
      category: "Voile",
      image: "https://images.unsplash.com/photo-1495954484750-af469f2f9be5?auto=format&fit=crop&w=600&q=80",
      slug: "ozone-rush-5"
    },
    {
      id: 3,
      title: "Gin Atlas 2",
      price: "2 500 €",
      condition: "Excellent",
      category: "Voile",
      image: "https://images.unsplash.com/photo-1517130038641-a774d04afb3c?auto=format&fit=crop&w=600&q=80",
      slug: "gin-atlas-2"
    }
  ];

  // Filter listings based on the current page
  const displayedListings = isHomePage ? listings.slice(0, 3) : listings;

  return (
    <section className="py-32 px-4 bg-retro-purple/5 dark:bg-retro-purple/80">
      <PageContainer>
        <div className="text-center mb-24">
          <h3 className="text-4xl md:text-5xl font-display uppercase mb-4 md:mb-8 text-retro-purple dark:text-white retro-shadow">
            Derniers objets volants identifiés
          </h3>
          <p className="text-xl text-retro-purple/80 dark:text-white/80 max-w-2xl mx-auto mb-12">
            Trouvez le prochain équipement qui vous accompagnera en l'air...
          </p>
          <div className="flex flex-col md:flex-row items-center justify-center gap-6">
            {isHomePage && (
              <Link 
                to="/materiel" 
                className="w-[80%] md:w-auto btn-primary inline-flex items-center justify-center gap-2 hover:gap-3 transition-all duration-300"
              >
                Voir le matériel
                <ArrowRight className="w-5 h-5" />
              </Link>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {displayedListings.map((item) => (
            <div key={item.id} className="card dark:bg-retro-purple/50 dark:border-retro-pink/20 overflow-hidden">
              <div className="relative h-48 overflow-hidden">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent"></div>
                <div className="absolute bottom-4 left-4">
                  <span className="text-sm font-display uppercase text-white bg-retro-blue px-3 py-1 rounded-full shadow-lg">
                    {item.category}
                  </span>
                </div>
              </div>
              <div className="p-6">
                <h4 className="text-xl font-display uppercase mb-2 text-retro-purple dark:text-white">
                  {item.title}
                </h4>
                <div className="flex justify-between items-center mb-4">
                  <span className="text-retro-orange font-medium">{item.price}</span>
                  <span className="text-retro-purple/80 dark:text-white/80">{item.condition}</span>
                </div>
                <Link 
                  to={`/materiel/${item.slug}`}
                  className="btn-primary w-full inline-flex items-center justify-center gap-2 hover:gap-3 transition-all duration-300"
                >
                  Voir les détails
                  <ArrowRight className="w-4 h-4" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </PageContainer>

      <ConstructionModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
      />
    </section>
  );
};

export default EquipmentPageGrid;