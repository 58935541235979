import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { NewsForm } from '../../features/news/components/NewsForm';
import { SuccessMessage } from '../../features/news/components/SuccessMessage';
import { createNews } from '../../features/news/api/news';
import type { CreateNewsData } from '../../features/news/types';
import { handleApiError } from '../../utils/error';

export default function NewsSubmit() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (data: CreateNewsData) => {
    try {
      setIsSubmitting(true);
      setError('');
      await createNews(data);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/actualites');
      }, 2000);
    } catch (err) {
      console.error('Error submitting article:', err);
      setError(handleApiError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-[73vh] bg-white dark:bg-retro-purple/95">
      <section className="pt-36 pb-20 px-4 relative overflow-hidden bg-gradient-to-b from-white dark:from-retro-purple to-retro-purple/5 dark:to-retro-purple/20">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,#FF69B4_0%,transparent_70%)] opacity-10"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,#00B4D8_0%,transparent_70%)] opacity-10"></div>
        
        <div className="max-w-3xl mx-auto relative z-10">
          <Link 
            to="/actualites"
            className="inline-flex items-center gap-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors mb-8"
          >
            <ArrowLeft className="w-4 h-4" />
            Retour aux actualités
          </Link>

          <h1 className="text-4xl font-display uppercase mb-6 text-retro-purple dark:text-white retro-shadow">
            Publier un article
          </h1>
          
          <div className="bg-white dark:bg-retro-purple/50 rounded-lg p-8 shadow-xl">
            <NewsForm
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              error={error}
            />
          </div>
        </div>
      </section>

      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm z-50">
          <SuccessMessage />
        </div>
      )}
    </div>
  );
}