export const navigationItems = {
  actualites: {
    label: 'Actualité',
    items: [
      { label: 'Lire les articles', href: '/actualites' }
    ]
  },
  materiel: {
    label: 'Matériel',
    items: [
      { label: 'Voir les équipements', href: '/materiel' },
      { label: 'Vendre un équipement', href: '/materiel/submit' }
    ]
  },
  boutique: {
    label: 'Boutique',
    href: '/boutique'
  }
};