import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { RegisterData, AuthorType } from '../../types/auth';

const authorTypes: { value: AuthorType; label: string }[] = [
  { value: 'club', label: 'Club' },
  { value: 'ecole', label: 'École' },
  { value: 'magasin', label: 'Magasin' },
  { value: 'fabricant', label: 'Fabricant' },
  { value: 'individu', label: 'Individu' }
];

export default function RegisterForm() {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const formData = new FormData(e.currentTarget);
      
      const data: RegisterData = {
        email: formData.get('email') as string,
        password: formData.get('password') as string,
        name: formData.get('name') as string,
        firstName: formData.get('firstName') as string,
        authorType: formData.get('authorType') as AuthorType,
      };

      await register(data);
      navigate('/');
    } catch (err: any) {
      console.error('Registration error:', err);
      if (err?.message?.includes('already registered')) {
        setError('Un compte existe déjà avec cette adresse email');
      } else {
        setError('L\'inscription a échoué. Veuillez réessayer.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      <div>
        <label htmlFor="firstName" className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
          Prénom
        </label>
        <input
          id="firstName"
          name="firstName"
          type="text"
          required
          className="w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 border-2 border-retro-purple/10 dark:border-white/10 
                   text-retro-purple dark:text-white
                   focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink transition-colors"
        />
      </div>

      <div>
        <label htmlFor="name" className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
          Nom
        </label>
        <input
          id="name"
          name="name"
          type="text"
          required
          className="w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 border-2 border-retro-purple/10 dark:border-white/10 
                   text-retro-purple dark:text-white
                   focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink transition-colors"
        />
      </div>

      <div>
        <label htmlFor="authorType" className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
          Publier en tant que
        </label>
        <select
          id="authorType"
          name="authorType"
          required
          className="w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 border-2 border-retro-purple/10 dark:border-white/10 
                   text-retro-purple dark:text-white
                   focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink transition-colors"
        >
          <option value="">Sélectionnez un type</option>
          {authorTypes.map(type => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
          Email
        </label>
        <input
          id="email"
          name="email"
          type="email"
          required
          className="w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 border-2 border-retro-purple/10 dark:border-white/10 
                   text-retro-purple dark:text-white
                   focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink transition-colors"
        />
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
          Mot de passe
        </label>
        <input
          id="password"
          name="password"
          type="password"
          required
          minLength={6}
          className="w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 border-2 border-retro-purple/10 dark:border-white/10 
                   text-retro-purple dark:text-white
                   focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink transition-colors"
        />
      </div>

      <button
        type="submit"
        disabled={isLoading}
        className="w-full btn-primary disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isLoading ? 'Inscription en cours...' : 'S\'inscrire'}
      </button>
    </form>
  );
}