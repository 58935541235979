import { useState, useEffect } from 'react';
import { getAllNews } from '../api/news';
import type { News } from '../newsTypes';

export function useNews() {
  const [news, setNews] = useState<News[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getAllNews();
        setNews(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Erreur lors du chargement des actualités');
      } finally {
        setIsLoading(false);
      }
    };

    fetchNews();
  }, []);

  return { news, isLoading, error };
}