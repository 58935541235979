import React, { useState } from 'react';
import { X, Facebook, Twitter, Linkedin, Mail, Link as LinkIcon, Check } from 'lucide-react';
import { cn } from '../../utils/cn';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  url: string;
  text?: string;
}

export function ShareModal({ isOpen, onClose, title, url, text }: ShareModalProps) {
  const [copied, setCopied] = useState(false);

  if (!isOpen) return null;

  const shareData = {
    title,
    text: text || title,
    url
  };

  const handleShare = async (platform: string) => {
    switch (platform) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(shareData.text)}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank');
        break;
      case 'email':
        window.location.href = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(`${text}\n\n${url}`)}`;
        break;
      case 'copy':
        try {
          await navigator.clipboard.writeText(url);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        } catch (err) {
          console.error('Failed to copy:', err);
        }
        break;
    }
  };

  const ShareButton = ({ platform, icon: Icon, label }: { platform: string; icon: React.ElementType; label: string }) => (
    <button
      onClick={() => handleShare(platform)}
      className={cn(
        "flex items-center gap-3 w-full p-4 rounded-lg transition-all duration-200",
        "text-retro-purple dark:text-white hover:bg-retro-purple/5 dark:hover:bg-white/5",
        "focus:outline-none focus:ring-2 focus:ring-retro-pink focus:ring-offset-2 dark:focus:ring-offset-retro-purple",
        platform === 'copy' && copied && "text-green-500 dark:text-green-400"
      )}
      aria-label={`Partager sur ${label}`}
    >
      <Icon className="w-5 h-5" />
      <span className="flex-1 text-left">{platform === 'copy' && copied ? 'Lien copié !' : label}</span>
      {platform === 'copy' && copied && <Check className="w-5 h-5" />}
    </button>
  );

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm" 
        onClick={onClose}
        aria-hidden="true"
      />
      
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="relative bg-white dark:bg-retro-purple/95 rounded-lg w-full max-w-md p-6 shadow-xl">
          <div className="absolute inset-0 bg-gradient-to-r from-retro-pink via-retro-orange to-retro-blue opacity-10 rounded-lg"></div>
          
          <button
            onClick={onClose}
            className="absolute -top-2 -right-2 p-4 text-retro-purple/60 dark:text-white/60 hover:text-retro-pink transition-colors"
            aria-label="Fermer"
          >
            <X className="w-8 h-8" />
          </button>

          <div className="relative mt-6">
            <h2 className="text-xl font-display uppercase mb-6 text-retro-purple dark:text-white">
              Partager la page
            </h2>

            <div className="space-y-2">
              <ShareButton 
                platform="facebook" 
                icon={Facebook} 
                label="Partager sur Facebook" 
              />
              <ShareButton 
                platform="twitter" 
                icon={Twitter} 
                label="Partager sur Twitter" 
              />
              <ShareButton 
                platform="linkedin" 
                icon={Linkedin} 
                label="Partager sur LinkedIn" 
              />
              <ShareButton 
                platform="email" 
                icon={Mail} 
                label="Envoyer par email" 
              />
              <ShareButton 
                platform="copy" 
                icon={LinkIcon} 
                label="Copier le lien" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}