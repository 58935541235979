import React from 'react';
import { ShoppingCart } from 'lucide-react';
import type { Product } from '../../types/shop';

interface ProductCardProps extends Product {
  layout: 'large' | 'square';
}

export function ProductCard({ name, price, image, colors, sizes, isNew, layout }: ProductCardProps) {
  return (
    <div className="group relative">
      <div className={`absolute inset-0 bg-gradient-to-r from-retro-pink to-retro-orange rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-500 ${layout === 'square' ? 'max-w-xs mx-auto' : ''}`}></div>
      <div className={`relative bg-white dark:bg-retro-purple/50 rounded-lg overflow-hidden ${layout === 'square' ? 'max-w-xs mx-auto' : ''}`}>
        <div className={`w-full overflow-hidden relative ${layout === 'square' ? 'aspect-[4/3]' : 'aspect-square'}`}>
          <img
            src={image}
            alt={name}
            className="h-full w-full object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent"></div>
          {isNew && layout === 'large' && (
            <div className="absolute top-4 right-4">
              <span className="inline-flex items-center rounded-full bg-retro-pink/90 backdrop-blur-sm px-3 py-1 text-sm font-medium text-white shadow-lg">
                Nouveau
              </span>
            </div>
          )}
          {sizes && (
            <div className="absolute bottom-4 left-4">
              <p className="text-sm text-white font-display uppercase">
                Tailles: {sizes.join(', ')}
              </p>
            </div>
          )}
        </div>
        
        <div className={`${layout === 'square' ? 'p-4' : 'p-6'}`}>
          <div className="flex justify-between items-start mb-4">
            <div>
              <h4 className={`font-display uppercase mb-2 text-retro-purple dark:text-white ${layout === 'square' ? 'text-lg' : 'text-xl'}`}>
                {name}
              </h4>
            </div>
            <p className={`font-display text-retro-orange ${layout === 'square' ? 'text-lg' : 'text-xl'}`}>{price}</p>
          </div>

          <div className="space-y-4">
            <div className="flex items-center gap-2">
              {colors.map((color, index) => (
                <div
                  key={index}
                  className={`rounded-full border-2 border-white dark:border-retro-purple shadow-md transform transition-transform duration-300 hover:scale-110 ${layout === 'square' ? 'h-4 w-4' : 'h-6 w-6'}`}
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>

            <button className="w-full btn-secondary group-hover:bg-retro-purple group-hover:text-white transition-all duration-300 flex items-center justify-center gap-2">
              <ShoppingCart className="w-4 h-4" />
              {layout === 'square' ? 'Acheter' : 'Ajouter au panier'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}