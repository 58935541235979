import React, { useState } from 'react';
import { Share2 } from 'lucide-react';
import { ShareModal } from './ShareModal';
import { cn } from '../../utils/cn';

interface ShareButtonProps {
  title: string;
  text?: string;
  className?: string;
  variant?: 'primary' | 'secondary';
  label?: string;
}

export function ShareButton({ 
  title, 
  text, 
  className, 
  variant = 'secondary',
  label = 'Partager la page'
}: ShareButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className={cn(
          "inline-flex items-center gap-2 hover:gap-3 transition-all duration-300",
          variant === 'primary' && "btn-primary",
          variant === 'secondary' && "btn-secondary",
          className
        )}
        aria-label="Partager"
      >
        {label}
        <Share2 className="w-5 h-5" />
      </button>

      <ShareModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        text={text}
        url={window.location.href}
      />
    </>
  );
}