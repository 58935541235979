import React, { useState } from 'react';
import { Search as SearchIcon, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function Search() {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/search?q=${encodeURIComponent(query)}`);
      setIsOpen(false);
      setQuery('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
        aria-label="Rechercher"
      >
        <SearchIcon className="w-5 h-5" />
      </button>

      {isOpen && (
        <>
          <div 
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40"
            onClick={() => setIsOpen(false)}
          />
          <form 
            onSubmit={handleSearch}
            className="absolute right-0 top-12 w-80 bg-white dark:bg-retro-purple/95 rounded-lg shadow-xl p-4 z-50 animate-in slide-in-from-top-2"
          >
            <div className="relative">
              <input
                type="search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Rechercher..."
                className="w-full pl-4 pr-10 py-2 rounded-lg bg-white dark:bg-retro-purple/30 
                         border-2 border-retro-purple/10 dark:border-white/10 
                         text-retro-purple dark:text-white
                         focus:outline-none focus:border-retro-pink"
                autoFocus
              />
              {query && (
                <button
                  type="button"
                  onClick={() => setQuery('')}
                  className="absolute right-2 top-1/2 -translate-y-1/2 p-1
                           text-retro-purple/60 dark:text-white/60 
                           hover:text-retro-pink transition-colors"
                >
                  <X className="w-4 h-4" />
                </button>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  );
}