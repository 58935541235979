import React from 'react';
import { PenLine } from 'lucide-react';
import { Link } from 'react-router-dom';
import { PageContainer } from '../layout/PageContainer';
import { ShareButton } from '../ui/ShareButton';

export default function NewsPageHero() {
  return (
    <section className="min-h-[73vh] pt-36 pb-28 px-4 relative overflow-hidden bg-gradient-to-b from-white dark:from-retro-purple to-retro-purple/5 dark:to-retro-purple/20 flex items-center">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,#FF69B4_0%,transparent_70%)] opacity-10"></div>
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,#00B4D8_0%,transparent_70%)] opacity-10"></div>
      
      <PageContainer className="relative z-10">
        <div className="max-w-3xl">
          <h2 className="text-4xl md:text-6xl font-display uppercase mb-6 leading-tight text-retro-purple dark:text-white retro-shadow">
            De la hauteur avec l'information
          </h2>
          
          <p className="text-xl text-retro-purple/80 dark:text-white/80 max-w-2xl mb-8">
            Découvrez les dernières innovations, suivez les performances des pilotes, restez au courant de la vie des clubs et des événements à venir. Pour vous informer ou vous divertir...
          </p>

          <div className="flex flex-wrap gap-4 items-center">
            <ShareButton 
              title="VolaVolo - Actualités Parapente"
              text="Découvrez les dernières actualités du monde du parapente"
              variant="secondary"
            />

            <Link 
              to="/news-addition"
              className="font-display uppercase tracking-wider text-retro-purple dark:text-white hover:text-retro-pink dark:hover:text-retro-pink transition-colors underline underline-offset-4 inline-flex items-center gap-2"
            >
              Soumettre un article
              <PenLine className="w-5 h-5" />
            </Link>
          </div>
        </div>
      </PageContainer>
    </section>
  );
}