import { supabase } from './supabase';
import type { Database } from '../types/database.types';

type Tables = Database['public']['Tables'];

export const api = {
  profiles: {
    async getById(id: string) {
      const { data, error } = await supabase
        .from('profiles')
        .select()
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async update(id: string, data: Partial<Tables['profiles']['Update']>) {
      const { data: profile, error } = await supabase
        .from('profiles')
        .update(data)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return profile;
    }
  },

  news: {
    async getAll() {
      const { data, error } = await supabase
        .from('news')
        .select(`
          *,
          profiles (
            id,
            first_name,
            last_name,
            role
          )
        `)
        .eq('status', 'published')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    },

    async getById(id: string) {
      const { data, error } = await supabase
        .from('news')
        .select(`
          *,
          profiles (
            id,
            first_name,
            last_name,
            role
          )
        `)
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async create(data: Tables['news']['Insert']) {
      const { data: news, error } = await supabase
        .from('news')
        .insert(data)
        .select()
        .single();

      if (error) throw error;
      return news;
    },

    async update(id: string, data: Partial<Tables['news']['Update']>) {
      const { data: news, error } = await supabase
        .from('news')
        .update(data)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return news;
    },

    async delete(id: string) {
      const { error } = await supabase
        .from('news')
        .delete()
        .eq('id', id);

      if (error) throw error;
    }
  }
};