import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import type { News } from '../newsTypes';

interface NewsNavigationProps {
  previousNews: News | null;
  nextNews: News | null;
}

export function NewsNavigation({ previousNews, nextNews }: NewsNavigationProps) {
  return (
    <nav className="flex justify-between items-center border-t border-retro-purple/10 dark:border-white/10 mt-12 pt-8">
      {previousNews ? (
        <Link
          to={`/actualites/${previousNews.id}`}
          className="inline-flex items-center gap-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
        >
          <ArrowLeft className="w-4 h-4" />
          Actualité précédente
        </Link>
      ) : (
        <div /> /* Spacer */
      )}

      {nextNews ? (
        <Link
          to={`/actualites/${nextNews.id}`}
          className="inline-flex items-center gap-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
        >
          Actualité suivante
          <ArrowRight className="w-4 h-4" />
        </Link>
      ) : (
        <div /> /* Spacer */
      )}
    </nav>
  );
}