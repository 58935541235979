import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ArrowRight, PenLine } from 'lucide-react';
import { useNews } from '../../features/news/hooks/useNews';
import { NewsList } from '../../features/news/components/NewsList';
import { PageContainer } from '../layout/PageContainer';

const NewsPageGrid = () => {
  const { news, isLoading, error } = useNews();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const displayedNews = isHomePage ? news.slice(0, 3) : news;

  return (
    <section className="py-16 md:py-32 px-2 md:px-4 bg-white dark:bg-retro-purple/90">
      <PageContainer>
        <div className="text-center mb-16 md:mb-24">
          <h3 className="text-4xl md:text-5xl font-display uppercase mb-4 md:mb-8 text-retro-purple dark:text-white retro-shadow">
            {isHomePage ? 'Dernières Actualités' : 'Actualités'}
          </h3>
          
          <p className="text-xl text-retro-purple/80 dark:text-white/80 max-w-2xl mx-auto mb-6 md:mb-10">
            Retrouvez tous les articles depuis la dernière information de la fédération, jusqu'au récit épique d'un passionant passioné...
          </p>
          <div className="flex flex-col md:flex-row items-center justify-center gap-4">
            {isHomePage && (
              <Link 
                to="/actualites" 
                className="w-[80%] md:w-auto btn-primary py-2 md:py-3 inline-flex items-center justify-center gap-2 hover:gap-3 transition-all duration-300"
              >
                Lire les articles
                <ArrowRight className="w-4 h-4 md:w-5 md:h-5" />
              </Link>
            )}
          </div>
        </div>

        <NewsList 
          news={displayedNews}
          isLoading={isLoading} 
          error={error} 
        />
      </PageContainer>
    </section>
  );
};

export default NewsPageGrid;