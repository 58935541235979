import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const LegalNotice = () => {
  const currentDate = new Date().toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="min-h-[73vh] bg-white dark:bg-retro-purple/95">
      {/* Hero Section */}
      <section className="pt-36 pb-20 px-4 relative overflow-hidden bg-gradient-to-b from-white dark:from-retro-purple to-retro-purple/5 dark:to-retro-purple/20">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,#FF69B4_0%,transparent_70%)] opacity-10"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,#00B4D8_0%,transparent_70%)] opacity-10"></div>
        
        <div className="max-w-[1200px] mx-auto relative z-10">
          <Link 
            to="/"
            className="inline-flex items-center gap-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors mb-8"
          >
            <ArrowLeft className="w-4 h-4" />
            Retour à l'accueil
          </Link>

          <h2 className="text-4xl md:text-6xl font-display uppercase mb-6 text-retro-purple dark:text-white retro-shadow">
            Mentions Légales
          </h2>
          
          <p className="text-xl text-retro-purple/80 dark:text-white/80">
            Informations légales et conditions d'utilisation du site VOLAVOLO.
          </p>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 px-4">
        <div className="max-w-[1200px] mx-auto">
          <div className="space-y-12 text-retro-purple dark:text-white/90">
            <section>
              <h3 className="text-3xl md:text-4xl font-display uppercase text-retro-purple dark:text-white retro-shadow">
                1. Éditeur du site
              </h3>
              <p className="mt-4 mb-4">Le site web VOLAVOLO est édité par :</p>
              <ul className="list-disc pl-6 space-y-2 mb-4">
                <li>Bob MAURANNE</li>
                <li>1 Rue du Chapitre, 13100 Aix-en-Provence</li>
                <li>Téléphone : 04 42 92 17 09</li>
                <li>Email : argf@gmail.com</li>
              </ul>
              <p>Le Directeur de la publication est: Bob MAURANNE</p>
            </section>

            <section>
              <h3 className="text-3xl md:text-4xl font-display uppercase text-retro-purple dark:text-white retro-shadow">
                2. Hébergement
              </h3>
              <p className="mt-4 mb-4">Ce site est hébergé par :</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Hostinger International Ltd.</li>
                <li>61 Lordou Vironos Street</li>
                <li>6023 Larnaca, Chypre</li>
                <li>Site web : www.hostinger.fr</li>
              </ul>
            </section>

            <section>
              <h3 className="text-3xl md:text-4xl font-display uppercase text-retro-purple dark:text-white retro-shadow">
                3. Propriété intellectuelle
              </h3>
              <p className="mt-4">
                L'ensemble de ce site relève de la législation française et internationale sur le droit d'auteur 
                et la propriété intellectuelle. Tous les droits de reproduction sont réservés. La reproduction 
                de tout ou partie de ce site sur un support électronique ou autre est formellement interdite 
                sauf autorisation expresse de Bob MAURANNE.
              </p>
            </section>

            <section>
              <h3 className="text-3xl md:text-4xl font-display uppercase text-retro-purple dark:text-white retro-shadow">
                4. Inscription utilisateur
              </h3>
              <p className="mt-4">
                L'utilisation de certaines fonctionnalités du site nécessite une inscription. Les utilisateurs 
                s'engagent à fournir des informations sincères et exactes lors de leur inscription.
              </p>
            </section>

            <section>
              <h3 className="text-3xl md:text-4xl font-display uppercase text-retro-purple dark:text-white retro-shadow">
                5. Petites annonces
              </h3>
              <p className="mt-4">
                Le service de petites annonces proposé sur ce site est entièrement gratuit. L'éditeur du site 
                n'intervient pas dans les transactions entre utilisateurs et décline toute responsabilité quant 
                au contenu des annonces publiées.
              </p>
            </section>

            <section>
              <h3 className="text-3xl md:text-4xl font-display uppercase text-retro-purple dark:text-white retro-shadow">
                6. Protection des données personnelles
              </h3>
              <p className="mt-4">
                Ce site ne collecte pas de données personnelles au sens du Règlement Général sur la Protection 
                des Données (RGPD). Les annonces publiées relèvent de la responsabilité de leurs auteurs. 
                L'éditeur du site se réserve le droit de modérer ou supprimer tout contenu inapproprié.
              </p>
            </section>

            <section>
              <h3 className="text-3xl md:text-4xl font-display uppercase text-retro-purple dark:text-white retro-shadow">
                7. Cookies
              </h3>
              <p className="mt-4">Ce site n'utilise pas de cookies.</p>
            </section>

            <section>
              <h3 className="text-3xl md:text-4xl font-display uppercase text-retro-purple dark:text-white retro-shadow">
                8. Loi applicable et juridiction
              </h3>
              <p className="mt-4">
                Les présentes mentions légales sont soumises au droit français. En cas de litige, les tribunaux 
                français seront seuls compétents.
              </p>
            </section>

            <p className="text-sm text-retro-purple/60 dark:text-white/60">
              Dernière mise à jour : {currentDate}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LegalNotice;